import { Layout } from '../../GlobalComponent/Layout/Layout';
import { AddTemplate } from './components/addTemplate';
import { Heading, Box } from '@chakra-ui/react';

export const WhatsappTemplate: React.FC = () => {
    return (
        <Layout>
            <Box display="flex" flexDirection="column" height="100vh">
                <Heading size="lg" textAlign="center" mt="10">
                    Bulk WhatsApp Template
                </Heading>

           
                <Box
                    pb="100px"
                    w="100%"
                    m="auto"
                    flex="1" 
                    overflowY="auto" 
                    maxH="calc(100vh - 150px)" 
                >
                    <AddTemplate />
                </Box>
            </Box>
        </Layout>
    );
};
