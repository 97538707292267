import styles from"./bulkQr.module.css"
import { useQrCount } from '../../../context/QrCountContext';

const ActiveQrCount = () => {
  const  {activatedQrs,downloadedQrs,notDownloadedQrs,totalQrs}=useQrCount();
  return<div>
    <div className={styles.animated_background}>
      <div>Total QR Count: {totalQrs}</div>
      <div>Activated QR Count: {activatedQrs}</div>
      <div>Downloaded QR Count: {downloadedQrs}</div>
      <div>NotDownloaded QR Count: {notDownloadedQrs}</div>
    </div>
  </div>;
};

export default ActiveQrCount;
