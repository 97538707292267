import { Table as ChakraTable, Thead, Tbody, Tr, Th, Td, Box, Button, Spinner, Text } from "@chakra-ui/react";
import { useTable } from "../hooks/table";



export const Table: React.FC<{ update: boolean }> = ({ update }) => {
    const {currentPage,totalPages,isLoading,template,handlePage}=useTable(update)
    

    return (
        <>
            {isLoading ? (<Box h="100vh" display="flex" justifyContent="center" alignItems="center" bg="gray.100">
                <Spinner size="lg" color="blue.500" />
            </Box>) :
                (<Box mb="100px">
                    {
                        template?.length === 0 ? <Box w="100%" display="flex"
                            justifyContent="center">No Data is Available</Box> : (<ChakraTable sx={{
                                "th, td": {
                                    textAlign: "center",
                                    verticalAlign: "middle",
                                },
                            }}>
                                <Thead>
                                    <Tr>
                                        <Th>Template Name</Th>
                                        <Th>Template Language</Th>
                                        <Th>Template Vars</Th>
                                        <Th>Template Header Type</Th>
                                        <Th>BUTTON Vars</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {template?.map((elem, index) => (
                                        <Tr key={index}>
                                            <Td>{elem.TEMPLATE}</Td>
                                            <Td>{elem.TEMPLATE_LANGUAGE_CODE}</Td>
                                            <Td>
                                                {elem.TEMPLATE_VARS?.length ? (
                                                    <ul>
                                                        {elem.TEMPLATE_VARS.map((variable, index) => (
                                                            <p key={index}>{variable}</p>
                                                        ))}
                                                    </ul>
                                                ) : (
                                                    "NA"
                                                )}
                                            </Td>
                                            <Td>{elem.TEMPLATE_HEADER_TYPE || "NA"}</Td>
                                            <Td>
                                                {elem.BUTTON_VARS?.length ? (
                                                    <ul>
                                                        {elem.BUTTON_VARS.map((variable, index) => (
                                                            <p key={index}>{variable}</p>
                                                        ))}
                                                    </ul>
                                                ) : (
                                                    "NA"
                                                )}
                                            </Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </ChakraTable>)
                    }
                    {totalPages > 1 && (
                        <Box display={"flex"} mt={"20px"} gap={"20px"} justifyContent={"center"} ml={"80px"} alignItems={"center"} mb={"20px"}>
                            <Button onClick={(e) => handlePage(-1)} isDisabled={currentPage === 1} colorScheme="green">Prev</Button>
                            <Text>{`${currentPage}/${totalPages}`}</Text >
                            <Button onClick={(e) => handlePage(1)} isDisabled={currentPage === totalPages} colorScheme="green">Next</Button>
                        </Box>
                    )}
                </Box>)
            }
        </>
    );
};
