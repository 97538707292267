import { Button, Input, Select, FormControl, Flex, Text, Box, FormLabel, Checkbox, PopoverTrigger, Icon, Popover, PopoverBody, PopoverContent, VStack, HStack } from "@chakra-ui/react"
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { Table } from "./table";
import { useAddTemplate } from "../hooks/addTemplate";




export const AddTemplate: React.FC = () => {
    const { isOpen, selectedOptions, data, added, handlChange, hanldeSubmit, handleSelect, handleMenuToggle, handleCheckboxChange, setIsOpen, getDisplayText } = useAddTemplate()



    return (
        <Box w="90%" m="auto">
            <Flex style={{ display: "flex", flexDirection: "column" }}>
                <Flex gap={8}>
                    <FormControl id="TEMPLATE" isRequired flex="1" minW="200px">
                        <FormLabel>Template Name</FormLabel>
                        <Input
                            placeholder="Enter template Name"
                            value={data?.TEMPLATE}
                            name="TEMPLATE"
                            onChange={handlChange}
                        />
                    </FormControl>
                    <FormControl id="TEMPLATE_LANGUAGE_CODE" isRequired flex="1" minW="200px">
                        <FormLabel >Template Language</FormLabel>
                        <Select value={data?.TEMPLATE_LANGUAGE_CODE} name="TEMPLATE_LANGUAGE_CODE" onChange={(e) => handleSelect("TEMPLATE_LANGUAGE_CODE", e.target.value)}>
                            <option value="en">en</option>
                        </Select>
                    </FormControl>
                    <FormControl flex="1" minW="200px">
                        <FormLabel >Template Header Type</FormLabel>
                        <Select value={data?.TEMPLATE_HEADER_TYPE} placeholder="Select Template Header Type " onChange={(e) => handleSelect("TEMPLATE_HEADER_TYPE", e.target.value)} >
                            <option value="IMAGE">IMAGE</option>
                            <option value="VIDEO">VIDEO</option>
                        </Select>
                    </FormControl>
                    <Box flex="1" minW="200px">
                        <FormControl id="TEMPLATE_VARS">
                            <FormLabel>Template Vars</FormLabel>

                            <Popover isOpen={isOpen} onClose={() => setIsOpen(false)} closeOnBlur>
                                <PopoverTrigger>
                                    <Button
                                        w="100%"
                                        h="40px"
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        border="1px"
                                        borderColor="gray.300"
                                        borderRadius="md"
                                        p="2"
                                        bg="white"
                                        onClick={handleMenuToggle}
                                    >
                                        <HStack>
                                            <Text isTruncated maxW="150px">{getDisplayText()}</Text>
                                        </HStack>
                                        <Icon as={isOpen ? ChevronUpIcon : ChevronDownIcon} />
                                    </Button>
                                </PopoverTrigger>
                                <PopoverContent w="100%">
                                    <PopoverBody p="2" maxH="150px" overflowY="auto">
                                        <VStack align="start" spacing={2}>
                                            <Box minW="100px" maxW="150px" _hover={{ bg: "gray.100", borderRadius: "md" }}>
                                                <Checkbox
                                                    isChecked={selectedOptions.includes("personalInfo.fullName")}
                                                    onChange={(e) => handleCheckboxChange("personalInfo.fullName", e)}
                                                    w="100%"
                                                >
                                                    Name
                                                </Checkbox>
                                            </Box>
                                        </VStack>
                                    </PopoverBody>
                                </PopoverContent>
                            </Popover>
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl id="BUTTON_VARS" flex="1" minW="200px">
                            <FormLabel>Button Vars</FormLabel>

                            <Select value={data?.BUTTON_VARS[0]} placeholder="Select Button Vars" onChange={(e) => handleSelect("BUTTON_VARS", e.target.value)} >
                                <option value="{{_id}}/1">Basic Info</option>
                                <option value="{{_id}}/2">Address</option>
                                <option value="{{_id}}/3">Emergency Contacts</option>
                                <option value="{{_id}}/4">Medical Profile</option>
                                <option value="{{_id}}/5">Insurance Info</option>
                                <option value="{{_id}}/6">Preferred Hospital</option>
                            </Select>
                        </FormControl>
                    </Box>
                </Flex>
                <Flex style={{ display: "flex", justifyContent: "center", width: "100%", marginTop: 50 }}>
                    <Button
                        color="white"
                        bg="#1fd151"
                        width="100%"
                        type="submit"
                        maxWidth={100}
                        onClick={hanldeSubmit}
                    >Submit</Button>
                </Flex>

            </Flex>
            <Box pt="50px" w="90%" m="auto">
                <Table update={added} />
            </Box>
        </Box>
    )
}