
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { fetchBulkTemplate } from "../Actions/action";

interface Data {
    TEMPLATE: string;
    TEMPLATE_CATEGORY: string;
    TEMPLATE_VARS?: string[];
    TEMPLATE_LANGUAGE_CODE: string;
    TEMPLATE_HEADER_TYPE?: string;
    BUTTON_VARS?: string[];
}

export const useTable=(update:Boolean)=>{

     const [template, setTemplate] = useState<Data[]>([])
        const [totalPages, setTotalPages] = useState<number>(1)
        const [isLoading, setIsLoading] = useState<boolean>(true)
        const [searchParams, setSearchParams] = useSearchParams();
        const initialPage = Number(searchParams.get("page")) || 1;
        const [currentPage, setCurrentPage] = useState<number>(initialPage)
    
        useEffect(() => {
            async function fetchData() {
                let data = await fetchBulkTemplate(currentPage)
                setTotalPages(data?.totalPages)
                setTemplate(data?.data)
                setIsLoading(false)
            }
            fetchData()
    
        }, [currentPage, update])
    
        const handlePage = (newpage: number) => {
            let current = currentPage + newpage
            setCurrentPage(current)
            setSearchParams({ page: current.toString() });
        }

        return {currentPage,totalPages,isLoading,template,handlePage}

}