import { getWhatsappTemplate ,addTemplateHttp} from "../../../Http/Index"




export const fetchBulkTemplate=async(page:number)=>{
      try{
       let res=await getWhatsappTemplate(page)
       
       return res?.data
      }catch(err){
        console.log(err)
      }
}

export const addTemplate=async(payload:any)=>{
  try{
    let res=await addTemplateHttp(payload)
    console.log(res,"res")
    return {status:true,msg:"Successfully Added"}
    

  }catch (err: any) {
   
    const errorMsg = err.response?.data?.message || err.message || "Something went wrong";

    return { status: false, msg: errorMsg };
  }

}