// import React from 'react'
import { Routes, Route } from "react-router-dom";
import Login from "../../Pages/Login/Login";
// import BenAddressData from '../../Pages/BenAddressData/BenAddressData'
import { Printing } from "../../Pages/Printing/Printing";
import { SendBulkWaMessage } from "../../Pages/SendBulkWaMessage/SendBulkWaMessage";
import { BenFilters } from "../../Pages/BenFilters/BenFilters";
import { PrintingDetails } from "../../Pages/PrintingDetails/PrintingDetails";
import { WrapperWaMessage } from "../../Pages/WaMessageDetails/WrapperWaMessage";
import { ManageUser } from "../../Pages/ManageUser/ManageUser";
import { WaResJobWrapper } from "../../Pages/WaResponseDetails/WaResJobWrapper";
import { WaResponseWrapper } from "../../Pages/WaResponseDetails/WaResponseWrapper";
import { PrivateRoute } from "../PrivateRoute/PrivateRoute";
import { GenerateQr } from "../../Pages/GenerateQr/GenerateQr";
import AccountNotFound from "../../Pages/NotFound/AccountNotFound";
import { EmailBulkMessage } from "../../Pages/EmailBulkMessage/EmailBulkMessage";
import { SingleEmailJob } from "../../Pages/EmailBulkMessage/screens/SingleEmailJob";
import {Beneficiary} from "../../Pages/BenificiaryHavingNoHospital/benificiary"
import AddComapny from '../../Pages/AddCompanies/AddCompanies'
import { AmbulanceProviders } from "../../Pages/AmbulanceProviders/AmbulanceProvidersTsx/AmbulanceProviders";
import DashboardWrapper from "../../Pages/DashBoard/DashboardWrapper";
import { CompanyProvider } from "../../GlobalComponent/CompanyContext/CompanyContext";
import { WhatsappTemplate } from "../../Pages/WhatsappTemplate/WhatsappTemplate";
import { QrCountProvider } from "../../context/QrCountContext";

const MainRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
            
        {/* just for running on local make changes while deployment */}
        <Route path="/accountNotFound" element={<AccountNotFound />} />
        <Route
          path="/print_ben_address"
          element={
            <PrivateRoute>
              <BenFilters />
            </PrivateRoute>
          }
        />
        <Route
          path="/manage_userdata"
          element={
            <PrivateRoute>
              <ManageUser />
            </PrivateRoute>
          }
        />
        <Route
          path="/manage_company"
          element={
            <PrivateRoute>
              <AddComapny />
            </PrivateRoute>
          }
        />
        <Route
          path="/print/:jobid"
          element={
            <PrivateRoute>
              <BenFilters />
            </PrivateRoute>
          }
        />
        <Route
          path="/printing-details/:jobid"
          element={
            <PrivateRoute>
              <PrintingDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="/printing"
          element={
            <PrivateRoute>
              <Printing />
            </PrivateRoute>
          }
        />
        <Route
          path="/bulk_wa_message"
          element={
            <PrivateRoute>
              <SendBulkWaMessage />
            </PrivateRoute>
          }
        />
        <Route
          path="/bulk_email"
          element={
            <PrivateRoute>
              <EmailBulkMessage />
            </PrivateRoute>
          }
        />
        <Route
          path="/bulk_email/:emailJobId"
          element={
            <PrivateRoute>
              <SingleEmailJob />
            </PrivateRoute>
          }
        />      
        <Route
          path="/bulk_wa_message/:jobId"
          element={
            <PrivateRoute>
              <WrapperWaMessage />
            </PrivateRoute>
          }
        />
        <Route
          path="/wa_response"
          element={
            <PrivateRoute>
              <WaResJobWrapper />
            </PrivateRoute>
          }
        />
        <Route
          path="/wa_response/:jobId"
          element={
            <PrivateRoute>
              <WaResponseWrapper />
            </PrivateRoute>
          }
        />
        <Route
          path="/"
          element={
            <QrCountProvider>              
            <CompanyProvider>
            <PrivateRoute>
              <DashboardWrapper />
            </PrivateRoute>
            </CompanyProvider>
            </QrCountProvider>
          }
        />
        <Route
          path="/generate-qr/:jobid"
          element={
            <PrivateRoute>
              <GenerateQr />
            </PrivateRoute>
          }
        />
        <Route
          path="/wa_radius"
          element={
            <PrivateRoute>
              <Beneficiary/>
            </PrivateRoute>
          }
        />
        <Route
          path="/manage_ambulance_providers"
          element={
            <PrivateRoute>
              <AmbulanceProviders/>
            </PrivateRoute>
          }
        />
        <Route
          path="/manage_whatsapp_template"
          element={
            <PrivateRoute>
              <WhatsappTemplate/>
            </PrivateRoute>
          }
        />
        <Route path="*" element={<h1>Not Found</h1>} />
      </Routes>
    </>
  );
};

export default MainRoutes;
