import { createContext, useContext, useEffect, useState } from "react";
import { getQrCountDetails } from "../Pages/DashBoard/Action/action";

interface QrCountContextProps {
    totalQrs: number;
    activatedQrs: number;
    downloadedQrs: number;
    notDownloadedQrs: number;
    isAvailableActiveQr: boolean;
    setIsAvailableActiveQr: (value: boolean) => void;
    refreshQrCounts: (queries: any) => Promise<void>; // Function to refresh QR count
}


const qrCountContext = createContext<QrCountContextProps | undefined>(undefined);

export const QrCountProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [totalQrs, setTotalQrs] = useState(0);
    const [activatedQrs, setActivatedQrs] = useState(0);
    const [downloadedQrs, setDownloadedQrs] = useState(0);
    const [notDownloadedQrs, setNotDownloadedQrs] = useState(0);
    const [isAvailableActiveQr, setIsAvailableActiveQr] = useState(false);
    const refreshQrCounts = async (queries: any) => {
        try {
            const response = await getQrCountDetails(queries);
            if (response) {
                setTotalQrs(response.totalQrs || 0);
                setActivatedQrs(response.activatedQrs || 0);
                setDownloadedQrs(response.DownloadedQrs || 0);
                setNotDownloadedQrs(response.notDownloadedQrs || 0);
                setIsAvailableActiveQr(response.notDownloadedQrs > 0);
            } else {
                alert("QR count details not found");
            }
        } catch (err: any) {
            alert(err?.data?.response?.message || "Something went wrong while fetching QR details");
        }
    };
    useEffect(() => {
     refreshQrCounts("");
        //eslint-disable-next-line
    }, []);
    return (<>
        <qrCountContext.Provider value={{ totalQrs, activatedQrs, downloadedQrs, notDownloadedQrs, isAvailableActiveQr, refreshQrCounts, setIsAvailableActiveQr }}>
            {children}
        </qrCountContext.Provider>

    </>)

}

export const useQrCount = () => {
    const context = useContext(qrCountContext);
    console.log('context:', context);
    if (!context) {
        throw new Error("useQrCount must be used within a QrCountProvider");
    }
    return context;
}