import { useState } from "react";
import { addTemplate } from "../Actions/action";

interface payload {
    TEMPLATE: string,
    TEMPLATE_LANGUAGE_CODE: string,
    TEMPLATE_VARS: string[],
    TEMPLATE_CATEGORY: string,
    BUTTON_VARS: string[],
    TEMPLATE_HEADER_TYPE: string
}


export const useAddTemplate=()=>{
    const [isOpen, setIsOpen] = useState(false);
        const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
        const [added,setadded]=useState(true)
        const [data, setData] = useState<payload>({
            TEMPLATE: "",
            TEMPLATE_LANGUAGE_CODE: "en",
            TEMPLATE_VARS: [],
            TEMPLATE_HEADER_TYPE: "",
            TEMPLATE_CATEGORY: "bulk",
            BUTTON_VARS: []
    
        })
        const handlChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
            let key = e.target.name
          
            setData({ ...data, [key]: e.target.value })
    
        }
        const hanldeSubmit = async () => {
            if (!data?.TEMPLATE) {
                alert("Please Enter Template Name")
                return
            }
            if(!data?.TEMPLATE_HEADER_TYPE&&data.TEMPLATE_VARS.length===0&&data.BUTTON_VARS.length===0){
              
                alert("Please select at least one field from Template Header, Template Variables, or Button Variables to proceed.")
                return
            }
            let template_name = data?.TEMPLATE.toUpperCase()
    
            let update = {
                "configurations": {
                    [template_name]: data
                }
            };
    
            let res = await addTemplate(update)
            
            if (res.status) {
                
                alert(res.msg)
                setData({
                    TEMPLATE: "",
                    TEMPLATE_LANGUAGE_CODE: "en",
                    TEMPLATE_VARS: [],
                    TEMPLATE_HEADER_TYPE: "",
                    TEMPLATE_CATEGORY: "bulk",
                    BUTTON_VARS: []
    
                })
                setadded(!added)
                setSelectedOptions([])
            } else {
                alert(res.msg)
            }
    
    
        }
    
        const handleSelect = (key: keyof payload, value: string) => {
            if (key === "BUTTON_VARS") {
                if (value === "") {
                    setData({ ...data, [key]: [] });
                } else {
                    setData({ ...data, [key]: [value] });
                }
            } else {
                setData({ ...data, [key]: value })
            }
        }
    
            
        const handleMenuToggle = () => {
            setIsOpen((prev) => !prev);
        };
    
        const handleCheckboxChange = (value: string, e: React.ChangeEvent<HTMLInputElement>) => {
            e.stopPropagation()
            setSelectedOptions((prev) => {
                if (prev.includes(value)) {
                    let filter=prev.filter((item) => item !== value)
                    setData({ ...data, TEMPLATE_VARS: filter})
                    return filter ;
                } else {
                    setData({ ...data, TEMPLATE_VARS: [...prev, value] })
                    return [...prev, value];
                }
            });
        };

        const getDisplayText = () => {
            if (selectedOptions.length === 0) return "Select Template Variables";
            if (selectedOptions.length > 2) return `${selectedOptions.length} selected`; 
            return selectedOptions.map((opt) => (opt === "personalInfo.fullName" ? "Name" : "Age")).join(", ");
        };

        
    
    return {isOpen,selectedOptions,data,added,handlChange,hanldeSubmit,handleSelect,handleMenuToggle,handleCheckboxChange,setIsOpen,getDisplayText}

}