import { Flex, Box, Tooltip, Icon } from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from "react-router-dom";
import { TbLayoutGridRemove, TbTicket } from "react-icons/tb";
import { FaWhatsapp } from "react-icons/fa";
import { GrStatusUnknown } from "react-icons/gr";
import { BiLogOut } from "react-icons/bi"
import { AiOutlinePrinter } from "react-icons/ai";
import { TbBuildingHospital } from "react-icons/tb";
import { IconType } from 'react-icons';
import { RiDashboardFill } from "react-icons/ri";
import Logout from '../../Pages/Logout/Logout';
import { MdOutlineEmail } from 'react-icons/md';
import { MdOutlineAddComment } from "react-icons/md"
import { FaAmbulance } from "react-icons/fa";
import { AiOutlineFileAdd } from "react-icons/ai";
interface SidebarItemProps {
    icon: IconType;  // Import IconType from 'react-icons' to ensure proper typing
    tooltip: string;
    url: string;
}
const SideBar: React.FC = () => {

    return (
        <>
            <Flex w={"5rem"} direction="column" height="auto" bg="gray.200" p={"5"}>
                <Logo />
                <SidebarItem icon={RiDashboardFill} tooltip="Dashboard" url="/" />
                <SidebarItem icon={TbTicket} tooltip="Search Beneficiary" url="/print_ben_address" />
                <SidebarItem icon={TbLayoutGridRemove} tooltip="Manage" url="/manage_userdata" />
                <SidebarItem icon={AiOutlinePrinter} tooltip="Print QR" url="/printing" />
                <SidebarItem icon={FaWhatsapp} tooltip="Send Wa Message" url="/bulk_wa_message" />
                <SidebarItem icon={MdOutlineEmail} tooltip="Send Email" url="/bulk_email" />
                <SidebarItem icon={GrStatusUnknown} tooltip="Wa Message Response" url="/wa_response" />
                <SidebarItem icon={TbBuildingHospital} tooltip="Search radius" url="/wa_radius" />
                <SidebarItem icon={MdOutlineAddComment } tooltip="Manage Company" url="/manage_company" />
                <SidebarItem icon={FaAmbulance } tooltip="create Ambulance Provider" url="/manage_ambulance_providers" />
                <SidebarItem icon={AiOutlineFileAdd } tooltip=" whatsapp bulk template" url="/manage_whatsapp_template" />
                <SidebarItem icon={BiLogOut} tooltip="Logout" url="" />
                {/* <SidebarItem icon={BsQrCode} tooltip="Generate Org QR" url="/genericQr" />
              <SidebarItem icon={BsSticky} tooltip="Print Stickers" url="/printStickers" />
              <SidebarItem icon={AiOutlineNotification} tooltip={"Notification Templates"} url="/notificationTemplate" />
              <SidebarItem icon={BiSupport} tooltip={"Support"} url="/support" /> */}
            </Flex>
        </>
    )
}
const Logo: React.FC = () => {


    return (
        <Box as="button" p={2} mb={4} textAlign="center">
            <img src="/Images/transparentLogo.png" alt="Logo" style={{ width: "34px", height: "34px" }} />
        </Box>
    );
};
const SidebarItem: React.FC<SidebarItemProps> = ({ icon, tooltip, url }) => {
    const navigate = useNavigate();

    const handleLogout = async () => {
        await Logout();
        navigate("/login");
        // localStorage.setItem('isLogin', "false");
    }

    return (
        <Tooltip label={tooltip} placement="right">
            <Box as="button" p={2} borderRadius="md" mb={2} _active={{ transform: "scale(0.9)" }}>
                <Icon onClick={() => url !== "" ? navigate(url) : handleLogout()} as={icon} boxSize={6} />
            </Box>
        </Tooltip>
    );
};


export default SideBar
