import React, { useState, ChangeEvent, useEffect, useCallback } from 'react';
import style from './benificiary.module.css';
import { Layout } from "../../GlobalComponent/Layout/Layout";
import { getCompanyList } from "./action/getAllCompany";
import { searchCompanyList } from "./action/searchcompany";
import { ShowAlert } from '../../GlobalComponent/Alerts/Alert';

interface Hospital {
    _id: string;
    companyName: string;
    subscriptionType: string;
    dateOfPartnership: string;
    isActivePartnership: boolean;
    isDeleted: boolean;
    __v: number;
}

interface FindData {
    _id: string;
    subscriberId: string;
    userId: string;
    personalInfo: Record<string, any>;
    login: boolean;
    address: Record<string, any>;
    medicalInfo: any[];
    insurancePolicies: any[];
    emergencyContacts: any[];
    consentsGivenTo: any[];
    havingConsentsOf: any[];
    isDeleted: boolean;
    progress: number;
    createdAt: string;
    updatedAt: string;
    __v: number;
}

export const Beneficiary: React.FC = () => {
    const [hospitalNames, setHospitalNames] = useState<Hospital[]>([]);
    const [selectedHospital, setSelectedHospital] = useState<string>('');
    const [radius, setRadius] = useState<number>(0);
    const [filteredData, setFilteredData] = useState<FindData[]>([]);
    const [numberOfPage, setNumberOfPage] = useState<number>(1);
    const [currentPage, setCurrentPage] = useState<number>(1);


    const increaseRadius = (): void => {
        if (radius <= 99) {
            setRadius(prevRadius => prevRadius + 1);
        }
    };

    const decreaseRadius = (): void => {
        setRadius(prevRadius => Math.max(prevRadius - 1, 0));
    };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const value = event.target.value;
        let replaced: number = Number(value.replace(/^0+/, ''));
        event.target.value = replaced + "";
        if (!isNaN(replaced) && replaced >= 0 && replaced <= 100) {
            setRadius(replaced);
        }
    };

    const handleClearSearch=(): void => {
        setRadius(0)
        setSelectedHospital(``)
        setFilteredData([])
        setNumberOfPage(1)
        setCurrentPage(1)
    };

    const handleSearchChange = (page:number): void => {
       setCurrentPage(1)
       setNumberOfPage(1)
       setFilteredData([])
        
        fetchingSearch(page);
    };

  const handleButtonChange=(page:number): void => {   
    
    fetchingSearch(page);
};

    const fetchingSearch = useCallback(async (page:number): Promise<void> => {
        try {
            if (!selectedHospital){
                alert("Please select company name");
                return;
            };
            if (radius <= 0) {
                alert("Please radius value should be greater than zero");
                return;
            }
            const listResponse = await searchCompanyList(selectedHospital, 10, page||currentPage, radius);
            const count = Math.ceil(listResponse.totalLength / 10);
           
            setNumberOfPage(count);
            setFilteredData(listResponse.data);
        } catch (err:any) {
            // console.error("Error fetching search results:", err,err?.response?.data?.message);
            ShowAlert("warning", err?.response?.data?.message.join(",")||"Error fetching search results:");
        }
    }, [selectedHospital, currentPage, radius]);

    const fetchData = async () => {
        try {
            const response = await getCompanyList();
            // console.log('companylist', response.data);
            setHospitalNames(response.data);
        } catch (err) {
            console.error("Error fetching hospital data:", err);
            ShowAlert("warning", "Error fetching hospital data");
        }
    };

    useEffect(() => {
        fetchData()
    }, [fetchingSearch]);
    console.log("filteredData",filteredData[0]);
    return (
        <Layout>
            <div className={style.container}>
                <h1 className={style.title}>Beneficiary Having No Hospital Dashboard Details</h1>
                <div className={style.formContainer}>
                    <div className={style.dropdownContainer}>
                        <label htmlFor="hospital-select" className={style.label}>Select Company</label>
                        <select
                            id="hospital-select"
                            value={selectedHospital}
                            onChange={(e) => setSelectedHospital(e.target.value)}
                        >
                            <option value="" disabled>Select a Company</option>
                            {hospitalNames.map((elem, index) => (
                                <option key={index} value={elem._id}>{elem.companyName}</option>
                            ))}
                        </select>
                    </div>
                    <div className={style.radiusControls}>
                        <p className={style.radiusLabel}>  Km</p>
                        <button onClick={decreaseRadius}>-</button>
                        <input
                            type="number"
                            value={radius}
                            onChange={handleInputChange}
                            placeholder='Enter radius'
                            max="100"
                            className={style.radiusInput}
                        />
                        <button onClick={increaseRadius}>+</button>
                    </div>
                    <div className={style.searchContainer}>
                        <button onClick={()=>handleSearchChange(1)} className={style.searchButton}>Search</button>
                        <button onClick={handleClearSearch} className={style.clearButton}>Clear Search</button>
                    </div>
                </div>
            </div>
            <div className={style.tableContainer}>
                {filteredData.length === 0 ? (
                    <h1 className={style.absence}>No Data available</h1>
                ) : (
                    <table>
                        <thead>
                            <tr>
                                <th>BenID</th>
                                <th>SubscriberId</th>
                                <th>Beneficiary Name</th>
                                <th>Mobile</th>
                                <th>Relationship</th>
                                <th>Address</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredData.map((item, index) => (
                                <tr key={index}>
                                    <td>{item._id}</td>
                                    <td>{item.subscriberId}</td>
                                    <td>{item?.personalInfo?.fullName || "Not available"}</td>
                                    <td>{item?.personalInfo?.mobile?.number || "Not available"}</td>
                                    <td>{item?.personalInfo?.relationshipToSubscriber || "Not available"}</td>
                                    <td>{(item?.address?.addressLine1 +", "+item?.address?.city+", "+item?.address?.pinCode+", "+item?.address?.country)?.toLocaleLowerCase() || "Not available"}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
            <div className={style.paginationContainer}>
                {numberOfPage > 1 && (
                    <div className={style.paginationControls}>
                        {Array.from({ length: numberOfPage }, (_, index) => (
                            <button
                                key={index + 1}
                                className={`${style.pageButton} ${currentPage === index + 1 ? style.activePage : ''}`}
                                onClick={(e) => {
                                   
                                   setCurrentPage(index+1)
                                   handleButtonChange(index+1)
                                }
                                    
                                }
                            >
                                {index + 1}
                            </button>
                        ))}
                    </div>
                )}
            </div>
        </Layout>
    );
};
