import React, { useState } from 'react';
import { RiDashboardFill } from "react-icons/ri";
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Heading,
    Icon,
    Input,
    Text,
    VStack,
} from '@chakra-ui/react';
import { createBulkQr } from '../../../Http/Index';
import { useQrCount } from '../../../context/QrCountContext';
import { QrDownload } from '../Action/action';

interface Props {
    mode: "generate" | "download";
    onSuccess?: () => void;
    downloadQrCount?: number;
    setDownloadQrCount?: (count: number) => void;
}

export function DocumentHandler({ mode, onSuccess }: Props) {
    const {isAvailableActiveQr,refreshQrCounts}=useQrCount();

    const [count, setCount] = useState<number>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string>('');
    // let companyName="";
    //   const buildQuery=buildQueries(companyName);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        try {
            if (mode === "generate") {

                await createBulkQr({ count });
                await refreshQrCounts("");//for dynamic update in the card count details
                onSuccess && onSuccess();
                setCount(0);
            } else {
                const indianDate = new Date().toLocaleString("en-IN", {
                    timeZone: "Asia/Kolkata",
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                    hour12: false,
                }).replace(/[/:,]/g, "_");
                
                const response = await QrDownload({count});
                setCount(0);
                await refreshQrCounts("");//for dynamic update in the card count details
                // await getQrCountDetails(buildQuery);//update dynamic counts//
                if(response?.data){
                    const blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                    const link = document.createElement("a");
                    link.href = URL.createObjectURL(blob);
                    link.download = `Bulk_QR_Codes-${indianDate}.xlsx`;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            }
        } catch (err:any) {
            // console.log('err:----', err);
            alert( err?.response?.data?.message || 'An error occurred');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box bg="white" p={6} borderRadius="lg" boxShadow="md">
            <Heading as="h2" size="lg" mb={4}>
                {mode === "generate" ? "Bulk QR Generator" : "Download Documents"}
            </Heading>
            <form onSubmit={handleSubmit}>
                <VStack spacing={4}>
                    { (
                        <FormControl>
                            <FormLabel>Number of Documents</FormLabel>
                            <Input
                                type="number"
                                value={count === 0 ? "" : count}
                                onChange={(e) => setCount(e.target.value ? +e.target.value : 0)}
                            />
                        </FormControl>
                    )}
                    {error && <Text color="red.500" fontSize="sm">{error}</Text>}
                    <Button
                        title={mode === "download" && !isAvailableActiveQr ? "createQr First" : ""}
                        type={mode === "generate" ? "submit" : "button"}
                        onClick={mode === "download" ? handleSubmit : undefined}
                        isLoading={loading}
                        loadingText={mode === "generate" ? "Generating..." : "Downloading..."}
                        colorScheme={mode === "generate" ? "blue" : "green"}
                        width="full"
                        isDisabled={mode === "download" && !isAvailableActiveQr}
                        leftIcon={loading ? undefined : <Icon as={RiDashboardFill} boxSize={8} />}
                    >
                        {mode === "generate" ? "Generate Documents" : "Download Excel"}
                    </Button>
                </VStack>
            </form>
        </Box>
    );
}
